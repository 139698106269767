<template>
  <div>
    <!--    <div class="border-top-primary border-3 border-table-radius px-0">-->
    <div>
      <filter-slot
        :filter-principal="filterPrincipal"
        :filter="filter"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reload="refresh"
      >
        <template #table>
          <b-table
            slot="table"
            ref="refSettingsList"
            small
            no-provider-filtering
            :items="getAllUsuarios"
            :fields="visibleFields"
            primary-key="id"
            table-class="text-nowrap"
            responsive="sm"
            show-empty
            sticky-header="70vh"
            :busy.sync="isBusy"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :current-page="paginate.currentPage"
            :per-page="paginate.perPage"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
            <template v-slot:cell(status_session)="data">
              <span v-if="data.item.status_session === 1">
                <feather-icon
                  icon="CircleIcon"
                  size="17"
                  style="fill: #00d25b; color: #00d25b"
                />
                Active
              </span>
              <span v-if="data.item.status_session === 2">
                <feather-icon
                  icon="CircleIcon"
                  size="17"
                  style="fill: #ff9f43; color: #ff9f43"
                />
                Busy
              </span>
              <span v-if="data.item.status_session === 3">
                <feather-icon
                  icon="CircleIcon"
                  size="17"
                  style="fill: #fc424a; color: #fc424a"
                />
                Away
              </span>
              <span
                v-if="
                  data.item.status_session === 4 ||
                  data.item.status_session === 0
                "
              >
                <feather-icon
                  icon="CircleIcon"
                  size="17"
                  style="fill: #82868b; color: #82868b"
                />
                Offline
              </span>
            </template>
            <template v-slot:cell(action)="data">
              <div
                v-if="
                  // (data.item.typesenior === 'Jr' ||
                  //   data.item.typesenior === 'Senior') &&
                  data.item.can_sell === 1
                "
              >
                <div
                  v-if="data.item.flag_settings_management === 'PENDING'"
                  class="d-flex justify-content-center"
                >
                  <b-button
                    v-b-modal.modal-action
                    class="
                      button
                      d-flex
                      flex-row
                      justify-content-center
                      align-items-center
                    "
                    variant="primary"
                    @click="onClickAction(data.item, 1)"
                  >
                    <feather-icon icon="ThumbsUpIcon" size="15" />
                  </b-button>
                  <b-button
                    v-b-modal.modal-action
                    class="
                      button
                      d-flex
                      flex-row
                      justify-content-center
                      align-items-center
                    "
                    variant="danger"
                    @click="onClickAction(data.item, 0)"
                  >
                    <feather-icon icon="ThumbsDownIcon" size="15" />
                  </b-button>
                </div>
              </div>
            </template>
            <template v-slot:cell(status)="data">
              <div class="d-flex justify-content-center align-v-center">
                <b-badge
                  v-if="
                    // (data.item.typesenior === 'Jr' ||
                    //   data.item.typesenior === 'Senior') &&
                    data.item.can_sell === 1 &&
                    data.item.flag_settings_management == 'PENDING'
                  "
                  class="text-center w-100 bg-light-warning"
                  pill
                >
                  PENDING
                </b-badge>
                <b-badge
                  v-if="
                    // (data.item.typesenior === 'Jr' ||
                    //   data.item.typesenior === 'Senior') &&
                    data.item.can_sell === 1 &&
                    data.item.flag_settings_management == 'APPROVED'
                  "
                  class="text-center w-100 bg-light-success"
                  style="cursor: pointer"
                  pill
                >
                  APPROVED
                </b-badge>
                <b-badge
                  v-if="
                    // (data.item.typesenior === 'Jr' ||
                    //   data.item.typesenior === 'Senior') &&
                    data.item.can_sell === 1 &&
                    data.item.flag_settings_management == 'DENIED'
                  "
                  class="text-center w-100 bg-light-danger"
                  pill
                >
                  DENIED
                </b-badge>
              </div>
            </template>
            <template #cell(tracking)="data">
              <div class="text-center">
                <b-button
                  variant="outline"
                  @click="openModalTracking(data.item)"
                >
                  <feather-icon
                    v-b-tooltip.hover.right="'Tracking'"
                    class="text-info"
                    icon="ListIcon"
                  />
                </b-button>
              </div>
            </template>
          </b-table>
        </template>
      </filter-slot>
    </div>
    <modal-tracking
      v-if="modalTrackingState"
      :data-item="dataItem"
      @hideModal="modalTrackingState = false"
    />
  </div>
</template>
<script>
import ModalTracking from "@/views/crm/views/settings/view/components/ModalTracking";
import { mapGetters } from "vuex";
import filters from "../data/filters.settings.data";
import fields from "../data/fields.settings.data";
import SettingsService from "../../service/settings.service";

export default {
  components: {
    ModalTracking,
  },
  data() {
    return {
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      sortBy: "created_at",
      sortDesc: true,
      fields,
      startPage: null,
      endPage: null,
      nextPage: null,
      toPage: null,
      isBusy: false,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      dataItem: [],
      modalTrackingState: false,
      filter: filters,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
  },
  methods: {
    openModalTracking(data) {
      this.dataItem = data;
      this.modalTrackingState = true;
    },
    async onClickAction(data, opcion) {
      try {
        const confirm = await this.showConfirmSwal();
        if (confirm.value) {
          this.addPreloader();
          const response =
            await SettingsService.updatePermissionManagementByIdUserModule({
              _user_session_id: this.currentUser.user_id,
              _user_module_id: data.user_module_id,
              _user_id: data.id,
              _opcion: opcion,
            });
          if (response.status === 200) {
            this.showSuccessSwal("Permission successfully changed");
            this.removePreloader();
            this.refresh();
          }
        } else {
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    refresh() {
      this.$refs.refSettingsList.refresh();
    },
    async getAllUsuarios() {
      try {
        const params = {
          texto: this.filterPrincipal.model,
          orderby: 1,
          order: "",
          per_page: this.paginate.perPage,
          page: this.paginate.currentPage,
          status: this.filter[0].model,
        };

        const response = await SettingsService.getAllUsuarios(params);
        this.startPage = response.data.from;
        this.paginate.currentPage = response.data.current_page;
        this.paginate.perPage = response.data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = response.data.last_page;
        this.totalRows = response.data.total;
        this.toPage = response.data.to;
        return response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style scoped>
#span_status_notes {
  padding: 3px 10px;
  border-radius: 15px;
  width: 115px;
}
#fluid {
  padding: 0;
}
.green {
  background: var(--success);
  color: #f2f2f2;
}
.red {
  background: var(--danger);
  color: #f2f2f2;
}
.yellow {
  background: var(--warning);
  color: #f2f2f2;
}
.button {
  padding: 0;
  margin: 5px 2.5px;
  height: 30px;
  width: 30px;
  border-radius: 20px;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
}
td {
  text-align: center;
}
</style>
