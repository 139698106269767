export default [
  {
    key: "id",
    label: "Id",
    visible: false,
  },
  {
    key: "usuario",
    label: "User",
    visible: true,
  },
  {
    key: "status_session",
    label: "Status session",
    visible: true,
  },
  {
    key: "typesenior_pending",
    label: "Level",
    visible: true,
  },
  {
    key: "action",
    label: "Action",
    visible: true,
    thClass: "text-center",
  },
  {
    key: "status",
    label: "Status",
    visible: true,
    thClass: "text-center",
  },
  {
    key: "tracking",
    visible: true,
    thClass: "text-center",
  },
];
