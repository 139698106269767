export default [
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Status',
    options: [
      { label: 'PENDING', id: 1 },
      { label: 'APPROVED', id: 2 },
      { label: 'DENIED', id: 3 },
    ],
    model: null,
    reduce: 'id',
    selectText: 'label',
    col: 12,
  },
]
