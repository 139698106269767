var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('filter-slot',{attrs:{"filter-principal":_vm.filterPrincipal,"filter":_vm.filter,"total-rows":_vm.totalRows,"paginate":_vm.paginate,"start-page":_vm.startPage,"to-page":_vm.toPage},on:{"reload":_vm.refresh},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('b-table',{ref:"refSettingsList",attrs:{"slot":"table","small":"","no-provider-filtering":"","items":_vm.getAllUsuarios,"fields":_vm.visibleFields,"primary-key":"id","table-class":"text-nowrap","responsive":"sm","show-empty":"","sticky-header":"70vh","busy":_vm.isBusy,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"current-page":_vm.paginate.currentPage,"per-page":_vm.paginate.perPage},on:{"update:busy":function($event){_vm.isBusy=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},slot:"table",scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(status_session)",fn:function(data){return [(data.item.status_session === 1)?_c('span',[_c('feather-icon',{staticStyle:{"fill":"#00d25b","color":"#00d25b"},attrs:{"icon":"CircleIcon","size":"17"}}),_vm._v(" Active ")],1):_vm._e(),(data.item.status_session === 2)?_c('span',[_c('feather-icon',{staticStyle:{"fill":"#ff9f43","color":"#ff9f43"},attrs:{"icon":"CircleIcon","size":"17"}}),_vm._v(" Busy ")],1):_vm._e(),(data.item.status_session === 3)?_c('span',[_c('feather-icon',{staticStyle:{"fill":"#fc424a","color":"#fc424a"},attrs:{"icon":"CircleIcon","size":"17"}}),_vm._v(" Away ")],1):_vm._e(),(
                data.item.status_session === 4 ||
                data.item.status_session === 0
              )?_c('span',[_c('feather-icon',{staticStyle:{"fill":"#82868b","color":"#82868b"},attrs:{"icon":"CircleIcon","size":"17"}}),_vm._v(" Offline ")],1):_vm._e()]}},{key:"cell(action)",fn:function(data){return [(
                // (data.item.typesenior === 'Jr' ||
                //   data.item.typesenior === 'Senior') &&
                data.item.can_sell === 1
              )?_c('div',[(data.item.flag_settings_management === 'PENDING')?_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-action",modifiers:{"modal-action":true}}],staticClass:"\n                    button\n                    d-flex\n                    flex-row\n                    justify-content-center\n                    align-items-center\n                  ",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.onClickAction(data.item, 1)}}},[_c('feather-icon',{attrs:{"icon":"ThumbsUpIcon","size":"15"}})],1),_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-action",modifiers:{"modal-action":true}}],staticClass:"\n                    button\n                    d-flex\n                    flex-row\n                    justify-content-center\n                    align-items-center\n                  ",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.onClickAction(data.item, 0)}}},[_c('feather-icon',{attrs:{"icon":"ThumbsDownIcon","size":"15"}})],1)],1):_vm._e()]):_vm._e()]}},{key:"cell(status)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center align-v-center"},[(
                  // (data.item.typesenior === 'Jr' ||
                  //   data.item.typesenior === 'Senior') &&
                  data.item.can_sell === 1 &&
                  data.item.flag_settings_management == 'PENDING'
                )?_c('b-badge',{staticClass:"text-center w-100 bg-light-warning",attrs:{"pill":""}},[_vm._v(" PENDING ")]):_vm._e(),(
                  // (data.item.typesenior === 'Jr' ||
                  //   data.item.typesenior === 'Senior') &&
                  data.item.can_sell === 1 &&
                  data.item.flag_settings_management == 'APPROVED'
                )?_c('b-badge',{staticClass:"text-center w-100 bg-light-success",staticStyle:{"cursor":"pointer"},attrs:{"pill":""}},[_vm._v(" APPROVED ")]):_vm._e(),(
                  // (data.item.typesenior === 'Jr' ||
                  //   data.item.typesenior === 'Senior') &&
                  data.item.can_sell === 1 &&
                  data.item.flag_settings_management == 'DENIED'
                )?_c('b-badge',{staticClass:"text-center w-100 bg-light-danger",attrs:{"pill":""}},[_vm._v(" DENIED ")]):_vm._e()],1)]}},{key:"cell(tracking)",fn:function(data){return [_c('div',{staticClass:"text-center"},[_c('b-button',{attrs:{"variant":"outline"},on:{"click":function($event){return _vm.openModalTracking(data.item)}}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:('Tracking'),expression:"'Tracking'",modifiers:{"hover":true,"right":true}}],staticClass:"text-info",attrs:{"icon":"ListIcon"}})],1)],1)]}}])})]},proxy:true}])})],1),(_vm.modalTrackingState)?_c('modal-tracking',{attrs:{"data-item":_vm.dataItem},on:{"hideModal":function($event){_vm.modalTrackingState = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }